import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../core/auth.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../environments/environment";
import { StoreService } from "../service/store.service";
import { UserService } from "../service/user.service";
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import { getAuth, RecaptchaVerifier } from "@angular/fire/auth";

interface IcampingUserModel {
  email: string;
  password: string;
}
declare global {
  interface Window {
    confirmationResult: any;
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  icampingUserModel = {} as IcampingUserModel;
  icampingUserModelResetPassword = {} as IcampingUserModel;
  newIcampingUserModel = {} as IcampingUserModel;
  showSpinner: boolean;
  showProgressBar: boolean;
  showLoginWindow: boolean;
  showPhoneLoginWindow: boolean;
  phoneModel: string = "";

  recaptchaVerifier!: RecaptchaVerifier;
  recaptchaWidgetId: number;
  waitForPhoneCode = false;
  confirmationResult: any;
  UserInputConfirmationSmsCode = "";

  @ViewChild("phoneVerifyCodeInput") phoneVerifyCodeInput!: ModalDirective;

  // Icamping Self Phone system
  phoneLoginNextaction = "";
  @ViewChild("phoneVerifyCodeInputV2") phoneVerifyCodeInputV2!: ModalDirective;
  @ViewChild("phoneLoginWithPassword") phoneLoginWithPassword!: ModalDirective;
  @ViewChild("phoneUserInput") phoneUserInput!: ModalDirective;

  phoneLoginPassword = "";
  phoneLoginPassword2 = "";
  userInputPhoneNumber = "";
  UserInputUsername = "";
  disableUserInputUsername = false;
  icampingPhoneTestMode = false;
  secretRouteToIcampingPhoneTestMode = "icamping_phone_testing_mode_laskdkmg";

  constructor(
    private _storeService: StoreService,
    private _userService: UserService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    public auth: AuthService
  ) {}

  userInputConfirmationSmsCodeValidators() {
    return /^(\d{1,10}|)$/.test(this.UserInputConfirmationSmsCode);
  }

  passwordValidators(password) {
    if (!password) {
      return true;
    }
    // Rule 1: Length >= 8
    if (password.length < 8) {
      return "密碼至少需8碼且包含英文字母與數字";
    }

    // Rule 2: At least one English character
    const hasEnglishChar = /[a-zA-Z]/.test(password);
    if (!hasEnglishChar) {
      return "密碼至少需8碼且包含英文字母與數字";
    }

    // If both rules are passed, the password is valid
    return true;
  }

  ngOnInit() {
    this.icampingPhoneTestMode =
      this.route.snapshot.paramMap.get("icamping_phone_testing_mode") ===
      this.secretRouteToIcampingPhoneTestMode;
    console.log(
      this.route.snapshot.paramMap.get("icamping_phone_testing_mode")
    );
    console.log(this.icampingPhoneTestMode);

    // To set reCAPTCHA language.
    const auth = getAuth();
    auth.languageCode = "zh-TW";

    this._storeService.hidePageLoading();
    const keepLoading = window.sessionStorage.getItem("keepLoading");

    if (keepLoading !== "true") {
      console.log("showSpinner is false");
      this.showSpinner = false;
    } else {
      console.log("showSpinner is true");

      this.showSpinner = true;
      setTimeout(() => {
        console.log("showSpinner for 5 sec.");
        this.showSpinner = false;
        window.sessionStorage.removeItem("keepLoading");
      }, 3000);
    }
    this.showLoginWindow = true;
    this.showPhoneLoginWindow = true;

    // Initialize reCAPTCHA verifier
    this.recaptchaVerifier = new RecaptchaVerifier(
      "signUpPhone-btn", // The container ID
      {
        size: "invisible",
        "data-sitekey": "6LfgyS4qAAAAAEMkdgOMCjx6LyliykafNJvq5Xow",
        callback: (response: any) => {
          console.log("reCAPTCHA solved, asking for verification code now.");
          console.log(response);
        },
        "expired-callback": () => {
          console.error("Recaptcha expired, please solve it again.");
        },
        "data-error-callback": (response: any) => {
          console.error("Recaptcha error happened.");
        },
      },
      this.auth.auth
    );

    this.recaptchaVerifier.render().then((widgetId) => {
      console.log("reCAPTCHA widget ID:", widgetId);
      this.recaptchaWidgetId = widgetId;
    });

    this.recaptchaVerifier.verify().then((token) => {
      console.log(token);
    });

    // listen for auth state changes
    this.auth;

    // listen for auth state changes
    this.auth.user$.subscribe((user) => {
      if (user && user.providerId === "phone") {
        console.log("calling new account api user for NON Phone user:", user);

        this._userService
          .UsersByPhoneNumber(user.uid)
          .subscribe((result: any) => {
            console.log(result);
            if (result.users.length === 0) {
              this.toastService.error("無訂單需要轉移");
              console.log("No old account found for this phone.");
              return;
            } else {
              // Save the user.uid as 'phone_firebase_uid'
              const phone_firebase_uid = encodeURIComponent(user.uid);

              // Filter users whose oauth_type is not 'phone'
              const nonPhoneUsers = result.users.filter(
                (user) => user.oauth_type !== "phone"
              );

              if (nonPhoneUsers.length === 0) {
                console.log("No non-phone users found.");
                return;
              }

              // Redirect to the accounts.icamping.app /noauth-redirect migration page
              let baseUrl = environment.accountsIcampingApp;
              // Add the phone_firebase_uid as part of the queryParams
              let queryParams =
                `phone_firebase_uid=${phone_firebase_uid}&` +
                nonPhoneUsers
                  .map((user, index) => {
                    const userFirebaseUidParam = `user_firebase_uid_${index}=${encodeURIComponent(
                      user.user_firebase_uid
                    )}`;
                    const oauthTypeParam = `oauth_type_${index}=${encodeURIComponent(
                      user.oauth_type
                    )}`;
                    const photoUrlParam = `photo_url_${index}=${encodeURIComponent(
                      user.photo_url || "https://avatar.iran.liara.run/public"
                    )}`;

                    return `${userFirebaseUidParam}&${oauthTypeParam}&${photoUrlParam}`;
                  })
                  .join("&");

              // Construct the full URL
              const redirectUrl = `${baseUrl}?${queryParams}`;
              console.log("Redirecting to:", redirectUrl);

              // set一個sessiionstorage 告訴 home component 有手機帳號可以做轉移
              // home component 會去檢查這個sessionstorage然後彈出 mdbModal問使用者要不要轉移
              window.sessionStorage.setItem(
                "has_phone_account_migration",
                "true"
              );
              window.sessionStorage.setItem(
                "phone_account_migration_redirect_url",
                redirectUrl
              );
            }
          });
      }
    });
  }

  isPhoneNumberValid(): boolean {
    const regex = /^09\d{8}$/; // Regex to validate Taiwan mobile phone numbers starting with 09 and followed by 8 digits
    return regex.test(this.phoneModel);
  }

  async signInWithPhone() {
    // 這行是為了防止用戶再點擊按鈕，手動設置 disabled
    const button = document.getElementById(
      "signUpPhone-btn"
    ) as HTMLButtonElement;
    button.disabled = true;
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true");
    if (this.confirmationResult && this.waitForPhoneCode) {
      this.phoneVerifyCodeInput.show();
      return;
    }
    // Ensure the phoneModel starts with '+886'
    if (this.isPhoneNumberValid()) {
      if (this.phoneModel.startsWith("0")) {
        this.phoneModel = this.phoneModel.substring(1); // Remove the leading '0'
      }
      this.phoneModel = `+886${this.phoneModel}`; // Add the '+886' prefix
      const phoneNumber = this.phoneModel; // Replace with dynamic phone number input
      const appVerifier = this.recaptchaVerifier;
      try {
        let confirmationResult = await this.auth.phoneLogin(
          this.auth.auth,
          phoneNumber,
          appVerifier
        );
        this.confirmationResult = confirmationResult;
        this.waitForPhoneCode = true;
        this.phoneVerifyCodeInput.show();
        console.log(this.confirmationResult);
      } catch (error) {
        console.log(error);
        this.toastService.error(
          "發送驗證簡訊失敗，請重新整理頁面後，再次嘗試登入，謝謝！",
          "登入錯誤",
          {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000); // 3 seconds delay 與 Toast 消失時間同步
        button.disabled = false;
      }
    } else {
      button.disabled = false;
      this.toastService.error("請輸入正確的手機號碼", "登入錯誤", {
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  phoneLoginConfirm() {
    const button = document.getElementById(
      "smsCodeConfirm-btn-phoneVerifyCodeInput"
    ) as HTMLButtonElement;
    button.disabled = true;

    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true");
    this.auth
      .phoneLoginConfirm(
        this.confirmationResult,
        this.UserInputConfirmationSmsCode
      )
      .then(() => {
        this.phoneVerifyCodeInput.hide();
        button.disabled = false;
      });
  }

  signInWithGoogle() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.googleLogin();
  }

  signInWithFacebook() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.facebookLogin();
  }

  signInWithIcamping(icampingUserModel) {
    console.log("Step1: 嘗試用該帳號密碼驗證舊愛露營使用者資料庫");
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286

    this.showLoginWindow = false;
    this.auth
      .emailLogin(icampingUserModel.email, icampingUserModel.password)
      .then((result: any) => {
        console.log(result);

        if (result.code === "auth/user-not-found") {
          console.log(
            "Step1: 該使用者沒有在新版愛露營建立過帳號，且與舊版愛露營帳號密碼驗證失敗！"
          );
          console.log("Step2: 為該使用者新增新版愛露營帳號！");
          // 新增該使用者到新版icamping, 建立好後會跑casebinding
          this.signUpWithIcamping(
            icampingUserModel.email,
            icampingUserModel.password
          );
        } else if (result.code === "auth/wrong-password") {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，但是Firebase密碼輸入錯誤！"
          );
          this.toastService.error("新版愛露營密碼錯誤");
          this.icampingUserModel.password = "";
          this.showSpinner = false;
          this.showLoginWindow = true;
          return false;
        } else {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，且Firebase密碼驗證，我們要把user導回到home！"
          );
          this.auth.updateUserData(result.user).then(() => {
            this.auth.icampingLocalStorageSet(
              result.user.email,
              result.user.uid
            );
            this.home();
          });
        }
      });
  }

  signUpWithIcamping(email: string, password: string) {
    if (email.includes("@gmail.com")) {
      return;
    }

    this.showSpinner = true;

    this.auth
      .emailSignUp(email, password)
      .then((authorized) => {
        if (authorized.user) {
          console.log("Step6: 註冊新版愛露營帳號成功!");
          console.log("Step7: 更新新版使用者資料到icamping-tree Firestore！");
          this.auth.updateUserData(authorized.user).then(() => {
            // console.log('Step8: 導到 /profile做手機驗證');
            console.log("Step8: 做case binding並導入home");
            this.auth.icampingLocalStorageSet(
              authorized.user.email,
              authorized.user.uid
            );
            this.afterSignIn();
          });
        } else {
          this.showSpinner = false;
          this.showLoginWindow = true;
        }
      })
      .catch((error) => {
        this.toastService.error(`愛入營帳號註冊失敗，請嘗試，謝謝`);
        console.log(error);
        this.showSpinner = false;
        this.showLoginWindow = true;
      });
  }

  resetPassword(icampingUserModelResetPassword) {
    this.auth
      .resetPassword(icampingUserModelResetPassword.email)
      .then(() => {});
  }

  signOut() {
    new Promise<void>((resolve, reject) => {
      try {
        this.auth.signOut();
        resolve(); // Resolve the promise once signOut is called
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    })
      .then(() => {
        // After the sign out process completes, reload the page
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  }

  async signInWithPhoneV2() {
    // 這行是為了防止用戶再點擊按鈕，手動設置 disabled
    const button = document.getElementById(
      "signUpPhone-btn"
    ) as HTMLButtonElement;
    button.disabled = true;

    window.sessionStorage.setItem("keepLoading", "true");

    // Ensure the phoneModel starts with '+886'
    if (this.isPhoneNumberValid()) {
      this.userInputPhoneNumber = this.phoneModel;
      if (this.userInputPhoneNumber.startsWith("0")) {
        this.userInputPhoneNumber = this.userInputPhoneNumber.substring(1); // Remove the leading '0'
      }
      this.userInputPhoneNumber = `+886${this.userInputPhoneNumber}`; // Add the '+886' prefix
      const phoneNumber = this.userInputPhoneNumber; // Replace with dynamic phone number input
      this._userService
        .icampingPhoneUserLoginOrCreate(phoneNumber)
        .subscribe((result: any) => {
          console.log(result);
          if (result.status === "error") {
            this.toastService.error(
              "手機登入失敗，請重新整理頁面後，再次嘗試登入，謝謝！",
              "登入錯誤",
              {
                timeOut: 3000,
                closeButton: true,
                progressBar: true,
              }
            );

            setTimeout(() => {
              window.location.reload();
            }, 3000); // 3 seconds delay 與 Toast 消失時間同步
          } else if (result.next_action === "login-with-password") {
            // 請用戶使用密碼登入
            this.phoneLoginNextaction = "login-with-password";
            // alert(this.phoneLoginNextaction);
            this.phoneLoginWithPassword.show();
          } else if (result.next_action === "verify-pincode") {
            // 請用戶使用密碼登入
            if (result.user_name && !result.user_name.startsWith("+886")) {
              this.disableUserInputUsername = true;
              this.UserInputUsername = result.user_name;
            }
            this.phoneLoginNextaction = "verify-pincode";
            // alert(this.phoneLoginNextaction);
            this.phoneVerifyCodeInputV2.show();
          } else {
            this.toastService.error(
              "手機登入失敗，請重新整理頁面後，再次嘗試登入，謝謝！",
              "登入錯誤",
              {
                timeOut: 3000,
                closeButton: true,
                progressBar: true,
              }
            );

            setTimeout(() => {
              window.location.reload();
            }, 3000); // 3 seconds delay 與 Toast 消失時間同步
          }
          button.disabled = false;
        });
    } else {
      button.disabled = false;
      this.toastService.error("請輸入正確的手機號碼", "登入錯誤", {
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  async signInWithPhoneVerifyCode() {
    console.log(this.userInputPhoneNumber);
    console.log(this.UserInputConfirmationSmsCode);
    console.log(this.phoneLoginPassword);
    console.log(this.phoneLoginPassword2);

    const button = document.getElementById(
      "smsCodeConfirm-btn-signInWithPhoneVerifyCode"
    ) as HTMLButtonElement;
    button.disabled = true;
    this.showProgressBar = true;

    window.sessionStorage.setItem("keepLoading", "true");
    this._userService
      .icampingPhoneUserVerify(
        this.userInputPhoneNumber,
        this.phoneLoginPassword,
        this.UserInputConfirmationSmsCode,
        this.UserInputUsername
      )
      .subscribe((result: any) => {
        console.log(result);
        this.showProgressBar = false;

        if (result.status === "error") {
          let errorMesgToShow = "";

          if (result.error_mesg === "No user tel") {
            errorMesgToShow = "無此手機號碼";
          } else if (result.error_mesg === "No pin code") {
            errorMesgToShow = "驗證碼錯誤";
          } else if (result.error_mesg === "No password") {
            errorMesgToShow = "請輸入密碼";
          } else if (result.error_mesg === "Wrong pin code") {
            errorMesgToShow = "驗證碼錯誤";
          } else if (result.error_mesg === "No user name") {
            errorMesgToShow = "請輸入真實姓名";
          }
          this.toastService.error(errorMesgToShow, "驗證錯誤", {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
          });
        } else if (result.next_action === "login-directly") {
          this.phoneLoginNextaction = "login-directly";
          // alert(this.phoneLoginNextaction);
          this.signUpWithIcampingV2_(
            `${this.userInputPhoneNumber}@phone.icamping.app`,
            this.phoneLoginPassword
          );
        } else {
          this.toastService.error("請聯繫愛露營客服專員", "驗證錯誤", {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
          });
        }
        button.disabled = false;
      });
  }

  async resetUserPhoneCode() {
    const button = document.getElementById(
      "smsCodeConfirm-btn-phoneUserInput"
    ) as HTMLButtonElement;
    button.disabled = true;
    this.showProgressBar = true;

    this.userInputPhoneNumber = this.phoneModel;
    if (this.userInputPhoneNumber.startsWith("0")) {
      this.userInputPhoneNumber = this.userInputPhoneNumber.substring(1); // Remove the leading '0'
    }
    this.userInputPhoneNumber = `+886${this.userInputPhoneNumber}`; // Add the '+886' prefix
    this._userService
      .icampingPhoneUserReset(this.userInputPhoneNumber)
      .subscribe((result: any) => {
        console.log(result);
        this.showProgressBar = false;

        if (result.status === "error") {
          let errorMesgToShow = "";

          if (result.error_mesg === "No user tel") {
            errorMesgToShow = "請輸入手機號碼";
          } else if (result.error_mesg === "No user found") {
            errorMesgToShow =
              "手機初次登入，請直接用手機號碼登入，系統將自動為您註冊";
            this.phoneUserInput.hide();
          }
          this.toastService.error(errorMesgToShow, "驗證錯誤", {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
          });
        } else if (result.next_action === "verify-pincode") {
          // 請用戶使用密碼登入
          this.phoneLoginNextaction = result.next_action;
          // alert(this.phoneLoginNextaction);
          if (result.user_name && !result.user_name.startsWith("+886")) {
            this.disableUserInputUsername = true;
            this.UserInputUsername = result.user_name;
          }
          this.phoneUserInput.hide();
          this.phoneVerifyCodeInputV2.show();
        } else {
          this.toastService.error("請聯繫愛露營客服專員", "錯誤", {
            timeOut: 3000,
            closeButton: true,
            progressBar: true,
          });
        }

        button.disabled = false;
      });
  }

  async signUpWithIcampingV2_(email: string, password: string) {
    if (!email.includes("@phone.icamping.app")) {
      return;
    }
    this.showProgressBar = true;
    this.auth
      .emailLogin(email, password)
      .then((result: any) => {
        console.log(result);
        if (result.code === "auth/user-not-found") {
          this.toastService.error("手機初次登入，請直接用手機號碼登入註冊");
        } else if (result.code === "auth/wrong-password") {
          this.toastService.error("密碼錯誤，請確認密碼是否正確或重新設定密碼");
          this.phoneLoginPassword = "";
          this.phoneLoginPassword2 = "";
          this.showProgressBar = false;
          this.showLoginWindow = true;
          return false;
        } else if (result.user) {
          console.log("Step-phone-login-1: 登入新版愛露營帳號成功!");
          this.auth.icampingLocalStorageSet(result.user.email, result.user.uid);
          console.log("Step-phone-login-2: 導入home");
          this.afterSignIn();
        }
      })
      .catch((error) => {
        this.toastService.error(`登入新版愛露營帳號失敗，請稍後再嘗試，謝謝`);
        console.log(error);
        this.showProgressBar = false;
        this.showLoginWindow = true;
      });
  }

  async signInWithPhoneAndPassword() {
    const button = document.getElementById(
      "smsCodeConfirm-btn-phoneLoginWithPassword"
    ) as HTMLButtonElement;
    button.disabled = true;
    this.showProgressBar = true;

    console.log(this.userInputPhoneNumber);
    console.log(this.UserInputConfirmationSmsCode);
    console.log(this.phoneLoginPassword);
    console.log(this.phoneLoginPassword2);

    await this.signUpWithIcampingV2_(
      `${this.userInputPhoneNumber}@phone.icamping.app`,
      this.phoneLoginPassword
    );
  }

  private afterSignIn() {
    this.home();
    this.showProgressBar = false;
  }

  private home() {
    window.location.href = `https://m.icamping.app`;
  }
}
